import React, { Fragment } from "react";
import SvgIcon from "../../../ui/svg-icon";
import TickIcon from "../../../../../images/svg/tick.svg";
import WarningIcon from "../../../../../images/svg/warning.svg";
import "./index.scss";

function PrivacyDetails(props) {
  const { beAware, goodToKnow, strings } = props;

  let goodToKnowList = [];
  let beAwareList = [];

  if (goodToKnow) {
    for (let i = 0; i < goodToKnow; i++) {
      goodToKnowList.push(
        <li key={`gtk${i}`}>
          <SvgIcon icon={TickIcon} name="tick" />
          <span dangerouslySetInnerHTML={{ __html: strings[`goodtoknow${i + 1}`] }} />
        </li>,
      );
    }
  }

  if (beAware) {
    for (let i = 0; i < beAware; i++) {
      beAwareList.push(
        <li key={`ba${i}`}>
          <SvgIcon icon={WarningIcon} name="warning" />
          <span dangerouslySetInnerHTML={{ __html: strings[`beaware${i + 1}`] }} />
        </li>,
      );
    }
  }

  return (
    <div className="app-picker-privacy">
      <div className="app-picker-privacy__content">
        {goodToKnow ? (
          <Fragment>
            <h3 className="app-picker-privacy__subtitle">Good To Know</h3>
            <ul className="app-picker-privacy__list">{goodToKnowList}</ul>
          </Fragment>
        ) : null}
        {beAware ? (
          <Fragment>
            <h3 className="app-picker-privacy__subtitle">Be Aware</h3>
            <ul className="app-picker-privacy__list">{beAwareList}</ul>
          </Fragment>
        ) : null}
      </div>
    </div>
  );
}

export default PrivacyDetails;
