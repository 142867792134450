export function replaceAll(string, search, replacement) {
  return string.split(search).join(replacement);
}

export const decodeBase64String = str => {
  // Base64 URL safe decode
  str = str.replace(/-/g, "+").replace(/_/g, "/");
  // Decode the string
  return atob(str);
};
