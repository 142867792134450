import React, { useEffect, useState } from "react";
import SchoolIcon from "../../../../images/png/school.png";
import Panel from "../../panel";
import "./index.scss";

const OrganisationName = ({ name: propName, organisationCount, setShowOrgPicker, onBlur }) => {
  const [name, setName] = useState(propName);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (!focused) {
      setName(propName);
    }
  }, [propName, focused]);

  const handleInputChange = e => {
    setName(e.target.value);
  };

  const handleInputBlur = e => {
    const value = e.target.value.trim();
    setFocused(false);
    if (value !== propName) {
      onBlur(value);
    }
  };

  return (
    <Panel className="organisation-name" title="Your Organization">
      <div className="organisation-name__icon">
        <img src={SchoolIcon} alt="" />
      </div>
      <div className="organisation-name__name">
        <input
          className="organisation-name__name-input fs-mask"
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onFocus={() => setFocused(true)}
          maxLength={128}
          value={name}
        />
        <pre className="organisation-name__name-display fs-mask">{name}</pre>
        {organisationCount > 0 ? (
          <button onClick={() => setShowOrgPicker(true)} className="organisation-name__switch">
            Switch organisation
          </button>
        ) : null}
      </div>
    </Panel>
  );
};

export default OrganisationName;
