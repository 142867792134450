import React from "react";
import MiniMenuItem from "../../ui/mini-menu/item";
import { OrganisationRole } from "../library-menu/const";
import LicenseIndicator from "../license-indicator";

const MenuItems = props => {
  const {
    featureFlags,
    hasDomainLicense,
    isApplyToAllLicense,
    isBookCreatorUser,
    licenseInfo,
    showLicenseSubMenu,
    onAddLibraryManagerRoleClick,
    onRemoveLibraryManagerRoleClick,
    onLicenseParentClick,
    onLicenseItemClick,
    onSendWelcomeEmailClick,
    onRemoveLicenseClick,
    onRemoveTeacherClick,
    onRequestCertificationClick,
    onSetUsersToDowngrade,
    showViewProfile,
    onViewProfileClick,
    onViewManageLibrariesClick,
    role,
    teacher,
    showManageLibraryMenu,
    showInactiveTeachers,
    onMarkAsActiveClick,
    // onDeleteTeacherClick,
  } = props;

  if (showInactiveTeachers) {
    return (
      <>
        <MiniMenuItem onClick={onMarkAsActiveClick} testid="teacher-menu__mark-as-active">
          Mark as Active
        </MiniMenuItem>
        {/* <MiniMenuItem
          onClick={onDeleteTeacherClick}
          type="negative"
          testid="teacher-menu__delete-account">
          Delete account
        </MiniMenuItem> */}
      </>
    );
  }

  if (showLicenseSubMenu) {
    return (
      <div>
        {licenseInfo.toJS().map(license => {
          const { bookQuota, libraryQuota, quantity, allocated } = license;
          const available = isApplyToAllLicense ? "Unlimited" : quantity - allocated;
          return (
            <MiniMenuItem
              key={license.id}
              onClick={e => onLicenseItemClick(license)}
              testid="teacher-menu__current-license">
              <LicenseIndicator bookQuota={bookQuota} libraryQuota={libraryQuota} available={available} />
            </MiniMenuItem>
          );
        })}
      </div>
    );
  }
  return (
    <div>
      {showViewProfile ? (
        <MiniMenuItem
          onClick={e => {
            onViewProfileClick();
          }}
          testid="teacher-menu__profile">
          View Profile
        </MiniMenuItem>
      ) : null}
      {!hasDomainLicense ? (
        <MiniMenuItem
          hasSubMenu={licenseInfo?.size}
          disabled={isApplyToAllLicense || !licenseInfo?.size}
          onClick={e => {
            onLicenseParentClick();
            e.stopPropagation();
          }}
          testid="teacher-menu__assign-license">
          Assign license
        </MiniMenuItem>
      ) : null}
      {!hasDomainLicense || isBookCreatorUser ? (
        <MiniMenuItem onClick={onSendWelcomeEmailClick} testid="teacher-menu__send-welcome-email">
          Send welcome email
        </MiniMenuItem>
      ) : null}
      <MiniMenuItem onClick={onRequestCertificationClick} testid="teacher-menu__request-certification">
        Request Certification
      </MiniMenuItem>
      {!hasDomainLicense ? (
        <MiniMenuItem
          onClick={onRemoveLicenseClick}
          disabled={teacher && !teacher.license}
          testid="teacher-menu__remove-license">
          Remove license
        </MiniMenuItem>
      ) : null}
      {featureFlags.orgLibraries && role === OrganisationRole.TEACHER ? (
        <MiniMenuItem onClick={onAddLibraryManagerRoleClick} testid="teacher-menu__assign-library-manager">
          Assign as Library Manager
        </MiniMenuItem>
      ) : null}
      {featureFlags.orgLibraries && role === OrganisationRole.MANAGER ? (
        <MiniMenuItem onClick={onRemoveLibraryManagerRoleClick} testid="teacher-menu__remove-library-manager">
          Remove as Library Manager
        </MiniMenuItem>
      ) : null}
      <MiniMenuItem
        onClick={onSetUsersToDowngrade}
        disabled={teacher?.pending}
        testid="teacher-menu__switch-to-student">
        Switch to student
      </MiniMenuItem>
      {showManageLibraryMenu ? (
        <MiniMenuItem onClick={onViewManageLibrariesClick} testid="teacher-menu__teacher-libraries">
          Manage Teacher Libraries
        </MiniMenuItem>
      ) : null}
      {isBookCreatorUser || (!hasDomainLicense && teacher?.pending) ? (
        <MiniMenuItem onClick={onRemoveTeacherClick} type="negative" testid="teacher-menu__remove-teacher">
          Remove teacher
        </MiniMenuItem>
      ) : null}
    </div>
  );
};

export default MenuItems;
